import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ListaLicitacaoComponent } from './lista-licitacao/lista-licitacao.component';
import { LoginGuard } from './shared/guard/login.guard';
import { MapaVendasComponent } from './mapa-vendas/mapa-vendas.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'mapavendas', component: MapaVendasComponent, canActivate: [LoginGuard] },
  { path: '', component: ListaLicitacaoComponent, canActivate: [LoginGuard] },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
