import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http'
import { LICITACAO_API } from '../app.api';
import { Observable } from 'rxjs'
import 'rxjs/add/operator/map'

import { Login, LoginResult } from '../model/login.model'


@Injectable()
export class LoginService {

    private tokenKey: string = 'token';
    private usuarioReadOnlyKey: string = 'usuarioReadOnly';
    constructor(private http: HttpClient) { }

    logar(login: Login): Observable<LoginResult> {
        return this.http.post<LoginResult>('/Login', login);
    }

    salvarToken(token: string): void {
        localStorage.setItem(this.tokenKey, token);
    }

    token(): string {
        return localStorage.getItem(this.tokenKey);
    }

    isLogado(): boolean {
        return localStorage.getItem(this.tokenKey) != null;
    }

    setUsuarioReadonly(readOnly: boolean): void {
        if (readOnly)
            localStorage.setItem(this.usuarioReadOnlyKey, 'true');
        else
            localStorage.removeItem(this.usuarioReadOnlyKey);
    }

    usuarioReadOnly(): boolean {
        return localStorage.getItem(this.usuarioReadOnlyKey) != null;
    }

    deslogar(): void {
        localStorage.clear();
    }

}