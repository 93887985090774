import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LicitacaoService } from '../service/licitacao.service';
import { Licitacao, Produto, Cliente, OperadorLogistico } from '../model/licitacao.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'


import 'rxjs/add/operator/switchMap'
import 'rxjs/add/operator/do'
import 'rxjs/add/operator/debounceTime'
import 'rxjs/add/operator/distinctUntilChanged'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/from'
import { Observable } from 'rxjs/Observable'
import { LICITACAO_API } from '../app.api';
import { DocumentoLicitacao } from '../model/documento.model';
import { Consultor } from '../model/consultor.model';
import { OWL_DATE_TIME_LOCALE, DateTimeAdapter } from 'ng-pick-datetime';
import { NativeDateTimeAdapter } from 'ng-pick-datetime/date-time/adapter/native-date-time-adapter.class';
import { Platform } from '@angular/cdk/platform';
import { LoginService } from '../service/login.service';



@Component({
  selector: 'app-lista-licitacao',
  templateUrl: './lista-licitacao.component.html',
  styleUrls: ['./lista-licitacao.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'pt-br'},
    {provide: DateTimeAdapter, useClass: NativeDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE, Platform]},
],
})
export class ListaLicitacaoComponent implements OnInit {

  constructor(private licitacaoService: LicitacaoService,
     private fb: FormBuilder, 
     private cd: ChangeDetectorRef,
     private loginService: LoginService
     ) { }

  licitacoes: Licitacao[] = null;

  public dateTime: Date;

  buscaHidden: boolean = true;

  editarLinha: boolean = false;

  licitacaoModal: Licitacao = null;

  searchForm: FormGroup
  searchControl: FormControl

  documentosBase64: any[] = [];

  formGroup = this.fb.group({});

  usuarioReadOnly() : boolean{
    return this.loginService.usuarioReadOnly();
  }

  statusEnum = {
    1: 'ANDAMENTO',
    2: 'NEGOCIAÇÃO',
    3: 'FECHADO'
  }
  idsStatus(): number[] {
    return [1, 2, 3];
  }
  autorizacaoEnum = {
    1: 'VIJAY',
    2: 'TAMIRES'
  }
  idsAutorizacao(): number[] {
    return [1, 2];
  }

  formArquivosSubmit(): void {
    this.formGroup.addControl('licitacao_ID', new FormControl(this.licitacaoModal.licitacao_ID, Validators.required));

    var fc = new FormControl();
    fc.setValue(this.documentosBase64.map(x => {
      return { fileName: x.fileName, file: x.conteudo };
    }));

    this.formGroup.addControl('documentos', fc);

    this.licitacaoService.enviarFormArquivos(this.formGroup.value)
    .subscribe(
      data => {
         alert('Arquivos enviados com sucesso.');
         this.modalDocumentos(this.licitacaoModal);
         },
      error => { alert('Erro ao tentar enviar os arquivos tente novamente mais tarde.') }
  );

  }

  onFileChange(event, filename) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);



      reader.onload = () => {

        this.documentosBase64.push({
          file: filename,
          fileName: file.name,
          conteudo: reader.result
        });

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };

      reader.onerror = () => {
        alert('Erro ao anexar o arquivo, tente novamente.');
      }
    }
  }

  listaProdutos: Produto[];
  listaClientes: Cliente[];

  listaConsultores: Consultor[] = [];

  listaArquivos: DocumentoLicitacao[];

  produtoTemp: Produto;

  documentosCount: number = 0;

  carregarDocumentos(id: number): void{
    this.licitacaoService.listaArquivosLicitacao(id).subscribe(
      data => this.listaArquivos = data == null ? [] : data.map(val => { val.Href = `${LICITACAO_API}/docs/${val.nomeArquivo}`; return val; }),
      error => console.log);
  }

  modalDocumentos(licitacao: Licitacao): void {
    this.documentosBase64 = [];
    this.documentosCount = 0;
    this.carregarDocumentos(licitacao.licitacao_ID);
    this.licitacaoModal = <Licitacao>Object.create(licitacao);
  }

  documentos(): string[] {
    var result = new Array<string>();

    for (let i = 0; i < this.documentosCount; i++)
      result.push('documento_' + i);

    return result;
  }

  addDocumento(): void {
    this.documentosCount++;
  }
  remDocumento(): void {
    if (this.documentosCount > 0) this.documentosCount--;
    if (this.documentosBase64.some(x => x.file == 'documento_' + this.documentosCount)) {
      ;//this.documentosBase64.indexOf()
    }
  }

  newLicitacaoModal(): void {
    this.licitacaoModal = new Licitacao();
    this.licitacaoModal.cliente = new Cliente();
    this.licitacaoModal.cliente.cnpj = "";
    this.licitacaoModal.produtos = [];
    this.licitacaoModal.operadorLogisticos = [];
    this.licitacaoModal.licitacao_ID = 0;
  }
  addLicitacao(): void {
    this.newLicitacaoModal();
    this.editarLinha = true;
  }

  setClienteTemp(cnpj: string): void {
    this.licitacaoModal.cliente = this.listaClientes.find(x => x.cnpj == cnpj);
  }

  setConsultor(id: number): void{
    this.licitacaoModal.consultor_id = id;
  }

  setProdTemp(cod: number): void {
    this.produtoTemp = this.listaProdutos.find(x => x.produto_Codigo == cod)
  }

  adicionarProduto(licitacao: Licitacao, produto: Produto): void {
    if (this.produtoTemp == null) {
      alert('Você precisa preencher os dados do produto.');
      return;
    }
    licitacao.produtos.push(produto);
    this.produtoTemp = null
  }

  removerProduto(licitacao: Licitacao, produto: Produto): void {
    licitacao.produtos.splice(licitacao.produtos.indexOf(produto), 1);
  }

  atualizarLicitacaoModal(licitacao: Licitacao, editar: boolean): void {
    this.produtoTemp = null
    this.editarLinha = editar;

    if (editar) {
      this.licitacaoService.listaLicitacao()
        .subscribe(x => this.licitacaoModal = x.find(l => l.licitacao_ID == licitacao.licitacao_ID));
    } else {
      this.licitacaoModal = licitacao;
    }
    this.licitacaoModal = <Licitacao>Object.create(licitacao);
  }

  buscar(busca: string): Observable<Licitacao[]> {

    var result: Observable<Licitacao[]>;

    if (busca.length == 0) {
      result = this.licitacaoService.listaLicitacao()
    }

    if (busca.length > 3) {
      result = this.licitacaoService.buscarLicitacao(busca)
    }
    return result;
  }

  listaOL: OperadorLogistico[];

  atualizarLicitacao(): void {

    this.licitacaoService.atualizarLicitacao(this.licitacaoModal)
      .subscribe(x => alert('Registro atualizado com sucesso.'));
    window.location.reload();

    this.getLicitacoes();
    // this.newLicitacaoModal();
  }
  olTemp: OperadorLogistico;

  setOLTemp(cnpj: string): void {
    this.olTemp = this.listaOL.find(x => x.cnpj == cnpj);
  }

  addOL(): void {
    if (this.olTemp == null) {
      alert('Selecione uma opção antes de adicionar.');
      return;
    }
    this.licitacaoModal.operadorLogisticos.push(this.olTemp);
    this.olTemp = null;
  }

  removeOL(ol: OperadorLogistico): void {
    this.licitacaoModal.operadorLogisticos.splice(this.licitacaoModal.operadorLogisticos.indexOf(ol), 1);
  }

  exportarExcel(): void {
    var url;

    var sheetName = prompt('Digite o nome do Book da planilha.', 'licitações');
    this.licitacaoService.exportarLicitacaoes(sheetName).subscribe((x: any) => this.downloadExcel(x));

  }
  downloadExcel(url): void {
    var win = window.open(LICITACAO_API + url.url, '_blank');
  }

  adicionarLicitacao(): void {
    this.licitacaoService.adicionarLicitacao(this.licitacaoModal)
      .subscribe(x => alert('Adicionado com sucesso.'));
    window.location.reload();
    this.getLicitacoes();
    // this.newLicitacaoModal();
  }

  getLicitacoes(): void {
    this.licitacaoService.listaLicitacao()
      .subscribe(result => this.licitacoes = result);
  }

  limparBusca(): void {
    this.searchControl.setValue('');
  }

  printModalToggle: boolean = false;
  printModal() : void{
    this.printModalToggle = true;
    setTimeout(() => {
      window.print();
    }, 20);

    setTimeout(() => {
      this.printModalToggle = false;
    }, 200);
  }

  ngOnInit() {
    this.licitacaoService.listaOL().subscribe(x => this.listaOL = x);
    this.licitacaoService.listaClientes().subscribe(x => this.listaClientes = x);

    this.getLicitacoes();

    this.licitacaoService.listaProdutos().subscribe(x => this.listaProdutos = x);

    this.licitacaoService.listaConsultores().subscribe(consultores => this.listaConsultores = consultores);

    this.searchControl = this.fb.control('');

    this.searchForm = this.fb.group({
      searchControl: this.searchControl
    });

    this.searchControl.valueChanges
      .debounceTime(500)
      .distinctUntilChanged()
      .switchMap(searchTerm =>
        this.buscar(searchTerm)
          .catch(error => this.licitacaoService.listaLicitacao()))
      .subscribe(result => this.licitacoes = result.filter(x => x.exibir),
        error => console.log(error)
      )
  }

}
