import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LoginService } from 'src/app/service/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  @Input() buscaHidden: boolean;
  @Output() buscaToggle: EventEmitter<any> = new EventEmitter();
  @Output() addEvent: EventEmitter<any> = new EventEmitter();
  @Output() exportEvent: EventEmitter<any> = new EventEmitter();

  constructor(private loginService: LoginService, private router: Router) { }

  buscarToggleEmit(): void {
    this.buscaToggle.emit();
  }

  mapaVendas():void{
    this.router.navigate(['/mapavendas'])
  }

  logOut(): void {
    if (confirm('Deseja sair ?')) {
      this.loginService.deslogar();
      this.router.navigate(['/login']);
    }
  }
  addEventEmit(): void{
    this.addEvent.emit();
  }

  exportEmit(): void{
    this.exportEvent.emit();
  }

  ngOnInit() {

  }

}
