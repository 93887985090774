export class Licitacao {
    constructor() { }
    licitacao_ID: number;
    empresa_id: number;
    status_cod: number;
    status_Descricao: string;
    autorizacao_id: number;
    consultor_id: number;
    acordo: boolean;
    contrato: boolean;
    exibir: boolean;
    linha_id: number;
    Narutereza_Juridica: string;
    dtHrNegociacao: Date;
    dtHrCadastro: Date;
    operadorLogisticos: OperadorLogistico[];
    cliente: Cliente;
    produtos: Produto[];
    observacao: string;
}
export class OperadorLogistico {
    constructor() { }
    licitacao_ID: number;
    operadorLogistico_id: number;
    nome: string;
    cnpj: string;
}

export class Cliente {
    constructor() { }
    cnpj: string;
    razaoSocial: string;
    fantasia: string;
    uf:string;
    cidade:string;
}

export class Produto {
    constructor() { }
    licitacoesXProduto_ID: number;
    licitacao_id: number;
    produto_Codigo: number;
    barra: string;
    nome: string;
    apresentacao: string;
    precoAutorizado: number;
    precoAutorizadoCX: number;
    demandaMensal: number;
    status_cod: number;
    dtHrCadastro: Date;
    margem: number;
    quantidade: number;
}