import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import 'rxjs/add/operator/map'
import { Licitacao, Produto, Cliente, OperadorLogistico } from '../model/licitacao.model';
import { DocumentoLicitacao } from '../model/documento.model';
import { Consultor } from '../model/consultor.model';




@Injectable()
export class LicitacaoService {

    constructor(private http: HttpClient) { }

    listaLicitacao(): Observable<Licitacao[]> {
        return this.http.get<Licitacao[]>('/Licitacao');
    }

    buscarLicitacao(busca: string): Observable<Licitacao[]> {
        return this.http.get<Licitacao[]>('/Licitacao/busca?busca=' + encodeURI(busca));
    }

    listaProdutos(): Observable<Produto[]> {
        return this.http.get<Produto[]>('/Produto');
    }

    listaClientes(): Observable<Cliente[]> {
        return this.http.get<Cliente[]>('/Empresa');
    }
    listaOL(): Observable<OperadorLogistico[]> {
        return this.http.get<OperadorLogistico[]>('/OL');
    }

    adicionarLicitacao(licitacao: Licitacao): Observable<any> {
        return this.http.put('/Licitacao', licitacao);
    }

    atualizarLicitacao(licitacao: Licitacao): Observable<any> {
        return this.http.post('/Licitacao', licitacao);
    }

    exportarLicitacaoes(sheetName: string): Observable<any> {
        return this.http.get<any>('/Licitacao/Download?sheetName=' + sheetName);;
    }

    listaArquivosLicitacao(LicitacaoId: number): Observable<DocumentoLicitacao[]> {
        return this.http.get<DocumentoLicitacao[]>('/LicitacaoArquivo?LicitacaoId=' + LicitacaoId);
    }

    enviarFormArquivos(form): Observable<any> {
        return this.http.post('/LicitacaoArquivo', form);
    }

    listaConsultores(): Observable<Consultor[]> {
        return this.http.get<Consultor[]>('/Consultor');;
    }
}