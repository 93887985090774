import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginService } from './service/login.service';
import { ListaLicitacaoComponent } from './lista-licitacao/lista-licitacao.component';
import { LicitacaoService } from './service/licitacao.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomHttpInterceptor } from './shared/interceptor/http.interceptor';
import { LoginGuard } from './shared/guard/login.guard';
import { CustomErrorHandler } from './shared/handler/error.handler';
import { NavBarComponent } from './shared/nav-bar/nav-bar.component';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MapaVendasComponent } from './mapa-vendas/mapa-vendas.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ListaLicitacaoComponent,
    NavBarComponent,
    MapaVendasComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  providers: [
    LoginService,
    LicitacaoService,
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    LoginGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}