export class Login {
    constructor(
        public email: string,
        public password: string
    ){}
}

export class LoginResult{
    token: string;
    readOnly: boolean;
}