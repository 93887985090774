import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { LoginService } from 'src/app/service/login.service';
import { LICITACAO_API } from 'src/app/app.api';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

    constructor(private loginService: LoginService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let url = req.url;

        if (!url.startsWith('http')) {
            url = LICITACAO_API + url;
        }


        req = req.clone({
            setHeaders: { Authorization: `Bearer ${this.loginService.token()}` },
            url
        });

        return next.handle(req);
    }
}