import { Component, OnInit, ApplicationRef } from '@angular/core';
import { LoginService } from '../service/login.service';
import { Login, LoginResult } from '../model/login.model';
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/from'
import { Observable } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private loginService: LoginService,
    private router: Router,
    private appRef: ApplicationRef
  ) { }

  email: string = "";
  senha: string = "";

  emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  loginInvalido: boolean = false;

  senhaValida(): boolean {
    return this.senha.length >= 3 || this.senha.length == 0;
  }

  emailValido(): boolean {
    return new RegExp(this.emailPattern).test(this.email) || this.email.length == 0;
  }

  logar(): void {
    this.loginService.logar(new Login(this.email, this.senha))
      .subscribe(
        x => this.entrar(x),
        error => this.dadosInvalidosMsg())
  }

  entrar(token): void {
    if (typeof (token) == typeof (String) || !token.token)
      token = JSON.parse(token)


    this.loginService.salvarToken(token.token);

    this.loginService.setUsuarioReadonly(token.readOnly);

    this.router.navigate(['/']);
  }

  dadosInvalidosMsg() {
    this.loginInvalido = true;
    setTimeout(() => {
      this.loginInvalido = false;
    }, 1200);
    return Observable.from([]);
  }

  ngOnInit() {
    if (this.loginService.isLogado()) {
      this.router.navigate(['/']);
    }
  }

}
