import { ErrorHandler, Injectable, Injector, ApplicationRef } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from 'src/app/service/login.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
    constructor(private readonly injector: Injector) { }

    handleError(error: any) {
        const router = this.injector.get(Router);
        const loginService = this.injector.get(LoginService);
        const app = this.injector.get(ApplicationRef);

        console.log(error);

        if (!error.status) { return; }

        switch (error.status) {
            case 401: {
                loginService.deslogar();
                router.navigate(['/login']).finally(() => {
                    //alert('Você precisa estar logado para acessar esta página.');
                    window.location.reload();
                });
            }
        }
    }
}
