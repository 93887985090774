import { Injectable, ApplicationRef } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { LoginService } from 'src/app/service/login.service';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly loginService: LoginService,
        private readonly application: ApplicationRef
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.loginService.isLogado()) {
            return true;
        }

        this.router.navigate(['/login']).finally(() => {
            window.location.reload();
        });

        return false;
    }
}